import {
  SET_KYC_DETAILS,
  SET_KYC_DOCS,
  SET_KYC_STATUS,
  SET_LOADING,
} from './kyc.action';
const initialState = {
  loading: false,
  kycDetails: null,
  kycDocs: null,
  kycStatus: null,
};

const kycReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_KYC_STATUS:
      if (data) {
        return {
          ...state,
          kycStatus: { ...state.kycStatus, ...data }
        };
      } else {
        return { ...state, kycStatus: null };
      }
    case SET_KYC_DETAILS:
    case SET_KYC_DOCS:
      return {
        ...state,
        ...data,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default kycReducer;
