import request from 'hb-redux-store/util/request';

export const getKycDetailsApi = () => request.get('/api/entityDetails');
export const putKycDetailsApi = data =>
  request.put('/api/entityRegistration', data);
export const getKycDocumentTypesApi = () => request.get('/api/documentTypes');
export const getKycDocsApi = () => request.get('/api/kycDocument');
export const putKycDocApi = data => request.put('/api/kycDocuments', data);
export const uploadKycDocApi = fileData =>
  request.postUpload('/api/upload-docs/kyc-docs', fileData);
export const submitForKycApprovalApi = () =>
  request.put('/api/submitForApproval');
