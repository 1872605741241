import { call, put, all, select, fork } from 'redux-saga/effects';
import {
  SET_ADDRESSES_FOR_LOCATION,
  SET_HOME_PAGE_SECTION,
  SET_LOADING,
  SET_OFFERS,
  SET_SPECIAL_PAGE_SECTION
} from './home.action';
import {
  getAddressesForLocationApi,
  getHomePageSectionApi,
  getOffersApi,
  getSpecialPageSectionApi,
  postUserLocationApi
} from './home.api';
import { HOMEPAGE_SECTIONS } from '../../util/constantServices';
import {
  SET_NON_SERVICEABLE_MODAL,
  SET_LOCATION_SEARCH_MODAL
} from '../modal/modal.action';
import history from '../../history';
import config from '../../../appConfig';
import request from '../../util/request';
import { RESET_CART_SKUS, SET_CART_QTY_COUNT } from '../cart/cart.action';
import { launchInfoSelector, SET_LAUNCH_INFO } from '../../initial.action';
import { getDiscount, getProcessesProductsBasedOnVariableWeight } from '../../util/miscellaneousServices';
import {getMembershipBannerDetailSaga} from 'hb-redux-store/feature/halalboxPrime/hbPrime.saga'

const { trackGtmEvent = () => {}, clevertap = null } = config;

export function* getHomePageSectionSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data } = yield call(getHomePageSectionApi);
    const sectionsPromise = [];
    const sections = data.sections.sort((a, b) => {
      if (
        a.position === b.position &&
        a.sectionType !== b.sectionType &&
        b.sectionType === HOMEPAGE_SECTIONS.CUSTOM_TILE
      ) {
        return b.position - a.position;
      }
      return a.position - b.position;
    });

    sections.map(s => {
      switch (s.sectionType) {
        case HOMEPAGE_SECTIONS.BANNER_CUSTOM_TILES:
        case HOMEPAGE_SECTIONS.CUSTOM_TILE:
          const bannerData = s.sectionData?.map(ct => {
            const {
              imageUrl,
              actionUrl,
              actionType,
              actionValue
            } = ct.tileRow[0].tileColumn[0];
            const banner = {
              type: s.sectionType,
              customTileId: ct.customTileId,
              bannerImage: imageUrl,
              actionType,
              actionValue,
              actionUrl,
              ct
            };
            return banner;
          });
          sectionsPromise.push({ data: bannerData });
          break;
        case HOMEPAGE_SECTIONS.CATEGORIES:
        case HOMEPAGE_SECTIONS.USP:
        case HOMEPAGE_SECTIONS.BLOGS:
        case HOMEPAGE_SECTIONS.BESTSELLERS:
        case HOMEPAGE_SECTIONS.DEALS_OF_THE_DAY:
        case HOMEPAGE_SECTIONS.NEWS:
        case HOMEPAGE_SECTIONS.COMBO_PRODUCTS:
        case HOMEPAGE_SECTIONS.PRE_ORDER:
        case HOMEPAGE_SECTIONS.RECENTLY_PURCHASED:
        case HOMEPAGE_SECTIONS.RECENTLY_VIEWED:
        case HOMEPAGE_SECTIONS.ATC_AND_CART_ABANDONED:
          sectionsPromise.push(
            call(() =>
              request
                .get(s.sectionData[0].apiUri)
                .catch(e => ({ data: false, error: e }))
            )
          );
          break;
        default:
          sectionsPromise.push(s);
      }
    });
    const sectionData = yield all(sectionsPromise);
    const respData = sectionData.map((sData, index) => {
      const section = sections[index];
      const returnData = {
        type: section.sectionType,
        position: section.position,
        sectionData: {
          ...section.sectionData,
          items: sData.data
        }
      };
      if (returnData.type === HOMEPAGE_SECTIONS.DEALS_OF_THE_DAY) {
        returnData.sectionData.title = 'Deals Of The Day';
        returnData.sectionData.items = returnData.sectionData.items.products;
      }
      if (returnData.type === HOMEPAGE_SECTIONS.BESTSELLERS) {
        returnData.sectionData.title = 'Bestsellers';
        returnData.sectionData.items = returnData.sectionData.items.products;
      }
      if (returnData.type === HOMEPAGE_SECTIONS.PRE_ORDER) {
        returnData.sectionData.title =
          returnData.sectionData.name || 'Pre Order';
        returnData.sectionData.items = returnData.sectionData.items.products;
        returnData.sectionData.info =
          returnData.sectionData.description ||
          'Pre order exotic meat products.';
        returnData.sectionData.moreInfoActionUrl =
          returnData.sectionData.actionUri;

        const items = returnData.sectionData?.items
          ?.map(p => {
            return {
              ...p,
              discount: getDiscount(p.skus[0])
            };
          })
          .sort((a, b) => b.discount - a.discount);
        returnData.sectionData.items = items;
      }
      if (returnData.type === HOMEPAGE_SECTIONS.RECENTLY_VIEWED) {
        returnData.sectionData.title = 'Recently Viewed';
        returnData.sectionData.items = returnData.sectionData.items.products;
      }
      if (returnData.type === HOMEPAGE_SECTIONS.RECENTLY_PURCHASED) {
        returnData.sectionData.title = 'Recently Purchased';
        returnData.sectionData.items = returnData.sectionData.items.products;
      }
      if (returnData.type === HOMEPAGE_SECTIONS.ATC_AND_CART_ABANDONED) {
        returnData.sectionData.title = 'You May Also Like';
        returnData.sectionData.items = returnData.sectionData.items.products;
      }

      return returnData;
    });

    yield put({
      type: SET_HOME_PAGE_SECTION,
      data: { homepageSection: respData }
    });
  } catch (e) {
    console.log('Err@ getHomePageSectionSaga: ', e);
    yield put({
      type: SET_HOME_PAGE_SECTION,
      data: { homepageSection: 'failed' }
    });
  }
}

export function* getHomePageAndBannerDetailSaga() {
  try {
    const {displayPrime} = yield select(launchInfoSelector);
    if(displayPrime){
      const promises = [
        call(getOptimizedHomePageSectionSaga),
        call(getMembershipBannerDetailSaga),
      ];
      yield all(promises);
    }else{
      yield call(getOptimizedHomePageSectionSaga)
    }
  } catch (e) {
    console.log('Err@ getHomePageAndBannerSaga: ', e);
  }
}

export function* getOptimizedHomePageSectionSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data } = yield call(getHomePageSectionApi);
    const sectionsPromise = [];
    const sections = data.sections.sort((a, b) => {
      if (
        a.position === b.position &&
        a.sectionType !== b.sectionType &&
        b.sectionType === HOMEPAGE_SECTIONS.CUSTOM_TILE
      ) {
        return b.position - a.position;
      }
      return a.position - b.position;
    });

    sections.map(s => {
      switch (s.sectionType) {
        case HOMEPAGE_SECTIONS.BANNER_CUSTOM_TILES:
        case HOMEPAGE_SECTIONS.CUSTOM_TILE:
          const bannerData = s.sectionData?.map(ct => {
            const {
              imageUrl,
              actionUrl,
              actionType,
              actionValue
            } = ct.tileRow[0].tileColumn[0];
            const banner = {
              type: s.sectionType,
              customTileId: ct.customTileId,
              bannerImage: imageUrl,
              actionType,
              actionValue,
              actionUrl,
              ct
            };
            return banner;
          });
          sectionsPromise.push({ data: bannerData });
          break;
        // case HOMEPAGE_SECTIONS.CATEGORIES:
        // case HOMEPAGE_SECTIONS.USP:
        // case HOMEPAGE_SECTIONS.BLOGS:
        // case HOMEPAGE_SECTIONS.BESTSELLERS:
        // case HOMEPAGE_SECTIONS.DEALS_OF_THE_DAY:
        case HOMEPAGE_SECTIONS.NEWS:
          // case HOMEPAGE_SECTIONS.COMBO_PRODUCTS:
          // case HOMEPAGE_SECTIONS.PRE_ORDER:
          sectionsPromise.push(
            call(() =>
              request
                .get(s.sectionData[0].apiUri)
                .catch(e => ({ data: false, error: e }))
            )
          );
          break;
        default:
          sectionsPromise.push(s);
      }
    });
    const sectionData = yield all(sectionsPromise);
    const respData = sectionData.map((sData, index) => {
      const section = sections[index];
      let items = sData.data;
      // console.log('section: ', section.sectionType, items?.products);
      if (items?.products?.length > 0) {
        items = { ...items, products: getProcessesProductsBasedOnVariableWeight(items.products)}
      }
      const returnData = {
        type: section.sectionType,
        position: section.position,
        sectionData: {
          ...section.sectionData[0],
          items,
        }
      };
      if (returnData.type === HOMEPAGE_SECTIONS.DEALS_OF_THE_DAY) {
        returnData.sectionData.title = 'Deals Of The Day';
      }
      if (returnData.type === HOMEPAGE_SECTIONS.BESTSELLERS) {
        returnData.sectionData.title = 'Bestsellers';
      }
      if (returnData.type === HOMEPAGE_SECTIONS.PRE_ORDER) {
        returnData.sectionData.title =
          returnData.sectionData.name || 'Pre Order';
        returnData.sectionData.info =
          returnData.sectionData.description ||
          'Pre order exotic meat products.';
        returnData.sectionData.moreInfoActionUrl =
          returnData.sectionData.actionUri;
        returnData.sectionData.minDealsCount =
          returnData.sectionData.count || 5;
        returnData.sectionData.moreInfo = 'View All';
      }
      if (returnData.type === HOMEPAGE_SECTIONS.RECENTLY_VIEWED) {
        returnData.sectionData.title = 'Recently Viewed';
      }
      if (returnData.type === HOMEPAGE_SECTIONS.RECENTLY_PURCHASED) {
        returnData.sectionData.title = 'Recently Purchased';
      }
      if (returnData.type === HOMEPAGE_SECTIONS.ATC_AND_CART_ABANDONED) {
        returnData.sectionData.title = 'You May Also Like';
      }
      return returnData;
    });

    yield put({
      type: SET_HOME_PAGE_SECTION,
      data: { homepageSection: respData }
    });
  } catch (e) {
    console.log('Err@ getHomePageSectionSaga: ', e);
    yield put({
      type: SET_HOME_PAGE_SECTION,
      data: { homepageSection: 'failed' }
    });
  }
}

export function* postUserLocationSaga({ data, cb }) {
  try {
    const { data: resp } = yield call(postUserLocationApi, data);
    const address = resp.customerAddress || resp.visitorAddress;
    if (resp.resetCart) {
      yield put({ type: SET_CART_QTY_COUNT, data: 0 });
      yield put({ type: RESET_CART_SKUS });
    }
    const launchData = yield select(launchInfoSelector);
    const launchInfo = {
      ...launchData,
      ...address,
      displayPrime: resp?.displayPrime || false,
      isSubscriptionActive: data?.isSubscriptionActive
    };
    yield put({ type: SET_LAUNCH_INFO, data: launchInfo });
    if (!config.isApp) {
      if (address.newAddress) {
        const gtmData = {
          eventName: 'locationConfirm',
          eventData: {
            category: `location > confirmed`,
            value: address.addressId,
            label: address.addressLine1 || address.addressLine2
          }
        };
        trackGtmEvent(gtmData);

        // Clevertap Analytics
        const key = 'Confirm Location';
        const payload = {
          ...data
        };
        if(clevertap) clevertap.sendCleverTapEvent(key, payload);
      }
      history.push('/');
    } else if (cb) {
      cb();
    }
    yield put({
      type: SET_LOCATION_SEARCH_MODAL,
      data: { locationSearchModal: false, typeOfCTA: 'location' }
    });
  } catch (e) {
    console.log('Err @postUserLocationSaga: ', e);
    if (!config.isApp) {
      const gtmData = {
        eventName: 'locationNotServiceable',
        eventData: {
          category: `location > not_serviceable`,
          value: data.pinCode,
          label: `lat: ${data.latitude}, long: ${data.longitude}`
        }
      };
      trackGtmEvent(gtmData);
      // Clevertap Analytics
      const key = 'Out Of Service Area';
      const payload = {
        ...data
      };
      if(clevertap) clevertap.sendCleverTapEvent(key, payload);
    }
    yield put({
      type: SET_NON_SERVICEABLE_MODAL,
      data: { visible: true, source: 'location' }
    });
  }
}

export function* getAddressesForLocationSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data } = yield call(getAddressesForLocationApi);
    yield put({ type: SET_ADDRESSES_FOR_LOCATION, data });
    yield put({ type: SET_LOADING, data: false });
  } catch (e) {
    console.log('Err@ getAddressesForLocationSaga: ', e);
    data?.failureCb && data.failureCb();
    yield put({ type: SET_ADDRESSES_FOR_LOCATION, data: [] });
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* getSpecialPageSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: pageData } = yield call(getSpecialPageSectionApi, data);
    const ctData = { ...pageData };
    ctData.sections = pageData.sections?.filter(
      s => s.sectionType === HOMEPAGE_SECTIONS.CUSTOM_TILE
    );
    yield put({ type: SET_SPECIAL_PAGE_SECTION, data: ctData });
  } catch (e) {
    console.log('Err@ getSpecialPageSaga: ', e);
    yield put({ type: SET_SPECIAL_PAGE_SECTION, data: { error: true } });
  }
}

export function* getOffersSaga() {
  try {
    const { data } = yield call(getOffersApi);
    yield put({ type: SET_OFFERS, data });
  } catch (e) {
    console.log('Err @getOffersSaga: ', e);
    yield put({ type: SET_OFFERS, data: null });
  }
}

export function* getSectionDataSaga({ data }) {
  try {
    const { apiUri, successCb = () => {} } = data;
    const { data: sectionResp } = yield call(request.get, apiUri);
    let processedResp = sectionResp;
    if (sectionResp?.products?.length > 0) {
      processedResp = {...sectionResp, products: getProcessesProductsBasedOnVariableWeight(sectionResp.products)};
    }
    successCb(processedResp);
  } catch (e) {
    console.log('Err @getSectionDataSaga: ', e, data);
    const { failCb = () => {} } = data;
    failCb(e);
  }
}
