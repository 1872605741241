import {
  ATC_VIBRATE_PATTERN,
  EMAIL_REGEX,
  HIGHLIGHT_REGEX,
  RUPEE_SYMBOL,
  EXTRACT_INTERPOLATION_TEXT,
  ATTRIBUTE_NAMES,
} from './constantServices';
import { getItem, setItem } from 'hb-redux-store/util/localStorageService';
import { STRIKE_TEXT_LIST } from 'hb-redux-store/constants/halalboxPrime';
import moment from 'moment';

//https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
export const uuidv4 = () => {
  let uuid = null;
  uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  setItem('did', uuid);
  return uuid;
};

export const resetDeviceId = () => uuidv4();

export const getDeviceId = () => {
  return getItem('did') || uuidv4();
};

export const getBackgroundImageUrl = image => ({
  backgroundImage: `url('${image}')`
});

export const getNameForUrl = text => text.replace(/[\W_]+/g, '-').toLowerCase();

export const isValidEmail = email => EMAIL_REGEX.test(email);

export const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

// export const generateActionLink = ({actionUri = null, actionType = null, actionValue = null}) => {
//   if (!(actionUri || (actionValue && actionType))) return '';
//   if (actionUri) return actionUri;
//   const action = ACTION_TYPE_DETAILS[actionType];
//   return action?.getUri(actionValue) || '';
// };

export function jsonToQueryString(json) {
  if (!json || Object.keys(json).length === 0) return '';
  return (
    '?' +
    Object.keys(json)
      .map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}

export function queryStringToJSON(qstring) {
  // eslint-disable-line
  if (!qstring) return {};
  const qs = qstring[0] === '?' ? qstring.substring(1) : qstring;
  const pairs = qs.split('&');
  const result = {};
  pairs.forEach(p => {
    const pair = p.split('=');
    const key = pair[0];
    const value = decodeURIComponent(p.substring(pair[0].length + 1) || '');
    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
      if (value === 'false') result[key] = false;
      if (value === 'true') result[key] = true;
    }
  });
  return JSON.parse(JSON.stringify(result));
}

export function constructNewLocation(
  pathname = '/',
  currentSearch = '',
  qs = {},
) {
  const query = queryStringToJSON(currentSearch);
  for (var key in qs) {
    query[key] = qs[key];
  }
  const search = jsonToQueryString(query);
  const newLoc = {
    pathname,
    search,
  };
  return newLoc;
}

export const copyStringToClipboard = (
  url,
  text,
  title,
  footer,
  successMessage = 'Copied to Clipboard',
) => {
  var el = document.createElement('textarea');
  el.value = `${title}\n\n${text}\n\n${url}\n\n${footer}\n`;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  // notification.success({
  //   message: successMessage,
  //   placement: 'bottomRight',
  //   duration: 1.5
  // });
};

export const copyPlainStringToClipboard = (
  string = '',
  successMessage = 'Copied to Clipboard',
) => {
  try {
    const el = document.createElement('textarea');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return Promise.resolve({ message: successMessage });
  } catch (e) {
    return Promise.reject({ message: 'Error copying code.' });
  }
};

export const shareContent = ({ url, text = '', title }) => {
  if (url && navigator && navigator.share) {
    return navigator
      .share({ url, text, title })
      .catch(error => copyPlainStringToClipboard(text));
  } else {
    return copyPlainStringToClipboard(text);
  }
};

export const getDiscount = sku => {
  if (!sku) return 0;
  if (typeof sku !== 'object') return 0;
  if (
    sku.salePrice === sku.retailPrice ||
    !sku.onSale ||
    sku.salePrice > sku.retailPrice // data inconsistency
  )
    return 0;
  if (sku.discount) return sku.discount;
  return Math.floor(
    ((sku.retailPrice - sku.salePrice) * 100) / sku.retailPrice
  );
};

export const getDiscountText = ({
  product = {},
  isCombo = false,
  isProduct = false,
  isCart = false,
}) => {
  let discountText = null;
  const getDiscount = discount => `${RUPEE_SYMBOL}${discount} OFF`;
  if (isCombo) {
    discountText = product.discountText || product.comboDiscountFlat;
    if (
      !discountText &&
      product.onSale &&
      product.salePrice > 0 &&
      product.retailPrice > product.salePrice
    ) {
      discountText = getDiscount(product.retailPrice - product.salePrice);
    }
  } else if (isProduct) {
    const [sku] = product.skus;
    discountText = sku?.discountText || sku?.saleDiscountFlat;
    if (
      !discountText &&
      sku.onSale &&
      sku.salePrice > 0 &&
      sku?.retailPrice > sku?.salePrice
    ) {
      discountText = getDiscount(sku.retailPrice - sku.salePrice);
    }
  } else if (isCart) {
    discountText = product.discountText || product.saleDiscountFlat;
    if (
      !discountText &&
      product.onSale &&
      product.salePrice > 0 &&
      product.retailPrice > product.salePrice
    ) {
      discountText = getDiscount(product.retailPrice - product.salePrice);
    }
  }

  return discountText;
};

export const vibrate = (pattern = ATC_VIBRATE_PATTERN) => {
  if (window.navigator?.vibrate) {
    window.navigator.vibrate(pattern);
  }
};

export const chooseRandomItem = (itemsArray = []) => {
  // https://www.codegrepper.com/code-examples/javascript/javascript+random+choice+from+a+list
  if (!itemsArray.length) return null;
  return itemsArray[Math.floor(Math.random() * itemsArray.length)];
};

export const flattenObject = ob => {
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObject(ob[i]);
      for (const j in temp) {
        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const convertArrayToObject = (arr = [], key = 'Product') => {
  return arr.reduce((obj, item, index) => {
    obj[`${key}_${index + 1}_`] = item;
    return obj;
  }, {});
};

export const extractProductItems = (arr = [], key = 'items') => {
  const item = [];
  arr.forEach(obj => {
    // item.push(obj[key]);
    if (Array.isArray(obj[key])) {
      // item.push(obj[key]);
      (obj[key] || []).forEach(itemData => item.push(itemData));
    }
  });
  return item;
};

export const extractHighLightText = (text = '') => {
  const matches = text.matchAll(EXTRACT_INTERPOLATION_TEXT);
  return Array.from(matches, item => item[1]) || [];
};

export const interPolation = (text = '') => {
  return text.split(HIGHLIGHT_REGEX).filter(Boolean);
};

export const isObjectEmpty = (obj = {}) => {
  return Object.keys(obj).length !== 0;
};

export const checkPrimeMemberShipSource = (list = [], source = '') => {
  return list.includes(source);
};

export const highlightService = (
  text = '',
  highLightTextList = [],
  banner = {}
) => {
  const isHighlightTextAvailable = highLightTextList.includes(text);
  const isStrikeTextAvailable = STRIKE_TEXT_LIST.includes(text);
  const textValue = banner[text];
  const interPolationText = textValue ?? text;
  const isNull = isHighlightTextAvailable && !textValue;
  const showStrikeText = !!textValue && isStrikeTextAvailable;
  return {
    isHighlightTextAvailable,
    isStrikeTextAvailable,
    interPolationText,
    isNull,
    showStrikeText,
    textValue,
  };
};

export const getMinutesAndSeconds = (timer = 0) => {
  const minutes = Math.floor(timer / 60);
  const seconds = Math.ceil(timer % 60);

  return { minutes, seconds };
};

export const getBuyNowTimer = time => {
  const TIME_OUT = 300000; // 5 Minutes
  const timeDifference = new Date().getTime() - time;
  return timeDifference < TIME_OUT;
};

export const getPreorderText = (sku, source = 'PLP') => {
  const { isPresale, deliveryStartDate, isInventoryAvailable } = sku;
  if (!isInventoryAvailable) return 'Get notified when available!';
  if (!isPresale) {
    if (source === 'PDP') return 'In 90 mins';
    return 'Express Delivery in 90 mins.';
  }
  const PREORDER_DELIVERY_TEXT =
    source === 'PLP' ? 'Pre-order delivery on' : 'Delivery on';
  const DELIVERS_TOMORROW_TEXT = 'Delivering Fresh Tomorrow Morning';
  const PREORDER_DATE_FORMAT = 'DD MMM YYYY';
  let tomorrow = moment()
    .add(1, 'days')
    .format(PREORDER_DATE_FORMAT);
  let deliveryDate = moment(deliveryStartDate).format(PREORDER_DATE_FORMAT);
  if (tomorrow === deliveryDate) return DELIVERS_TOMORROW_TEXT;
  return `${PREORDER_DELIVERY_TEXT}: ${moment(deliveryStartDate).format(
    PREORDER_DATE_FORMAT
  )}`;
};

export const getSelectedMultipleSkuIndex = (skus = []) => {
  const index = skus.findIndex(item => item.isSelected);
  return index > -1 ? index : 0;
};

export const getRandomInteger = (length = 5) => {
  const multiplier = 100000;
  return Math.round(Math.random() * multiplier);
};

export const getFileName = (file, docType = '') => {
  const { uid = `${getRandomInteger()}-${getRandomInteger()}` } = file;
  const textArray = file.name.split('.');
  return `${docType}_${uid}.${textArray[textArray.length - 1]}`;
};

export const sortAlphabetically = (arr, key, isAsc = true) => {
  if (!Array.isArray(arr)) return arr;
  return arr.toSorted((a, b) => {
    const nameA = a[key] ? a[key].toLowerCase() : a;
    const nameB = b[key] ? b[key].toLowerCase() : b;
    if (isAsc) {
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    } else {
      return nameA < nameB ? 1 : nameA > nameB ? -1 : 0;
    }
  });
};

export const getProcessesProductsBasedOnVariableWeight = (products = [], isPDP = false) => {
  // TODO: handle multiple sku case for PDP.
  // Stop spreading/expanding the skus into multiple products

  const processedProducts = [];
  products.map(p => {
    const hasVariableWeight =
      p.attributes
        ?.find(attr => attr.name === ATTRIBUTE_NAMES.HAS_VARIABLE_WEIGHT)
        ?.value.toLowerCase() === 'true';
    if (p.skus.length) {
      p.skus.map(sku => {
        const product = { ...p, hasVariableWeight };
        if (hasVariableWeight) {
          let minWeight = null,
            maxWeight = 0;
          try {
            if (sku.skuAttributes?.length)
              sku.skuAttributes.map(attr => {
                if (attr.name === ATTRIBUTE_NAMES.MIN_WEIGHT)
                  minWeight = parseFloat(attr.value);
                if (attr.name === ATTRIBUTE_NAMES.MAX_WEIGHT)
                  maxWeight = parseFloat(attr.value);
              });
            const newAttrs = [];
            if (maxWeight)
              newAttrs.push({
                displayName: 'Max Weight',
                showTop: true,
                value: `${maxWeight} kg`
              });
            newAttrs.push({
              displayName: 'Per kg price',
              showTop: true,
              value: `${RUPEE_SYMBOL}${
                sku.onSale && sku.perKgSalePrice < sku.perKgRetailPrice
                  ? sku.perKgSalePrice
                  : sku.perKgRetailPrice
              }`
            });
            newAttrs.push({
              displayName: 'Estimated price',
              showTop: true,
              value: `${RUPEE_SYMBOL}${
                sku.onSale && sku.salePrice < sku.retailPrice
                  ? sku.salePrice
                  : sku.retailPrice
              }`
            });
            // if (minWeight) newAttrs.push({
            //   displayName: "Min Weight",
            //   showTop: true,
            //   value: `${minWeight} kg`,
            // });
            sku.skuAttributes = newAttrs;
          } catch (e) {
            product.skus = [sku];
            console.log('Error getting minMaxWeight: ', e);
          }
          product.skus = [{ ...sku, minWeight, maxWeight }];
        } else {
          product.skus = [sku];
        }
        processedProducts.push(product);
      });
    }
  });
  return processedProducts;
};
