const prefix = 'KYC/';

export const GET_KYC_DETAILS = `${prefix}GET_KYC_DETAILS`;
export const SET_KYC_DETAILS = `${prefix}SET_KYC_DETAILS`;
export const SET_KYC_STATUS = `${prefix}SET_KYC_STATUS`;
export const PUT_KYC_DETAILS = `${prefix}PUT_KYC_DETAILS`;
export const GET_KYC_DOCS = `${prefix}GET_KYC_DOCS`;
export const SET_KYC_DOCS = `${prefix}SET_KYC_DOCS`;
export const PUT_KYC_DOC = `${prefix}PUT_KYC_DOC`;
export const PUT_KYC_STATUS = `${prefix}PUT_KYC_STATUS`;
export const SET_LOADING = `${prefix}SET_LOADING`;

export const getKycDetails = () => ({ type: GET_KYC_DETAILS });
export const putKycDetails = data => ({ type: PUT_KYC_DETAILS, data });

export const getKycDocs = () => ({ type: GET_KYC_DOCS });
export const uploadAndUpdateKycDocs = data => ({ type: PUT_KYC_DOC, data });

export const requestForKycApproval = data => ({ type: PUT_KYC_STATUS, data });
