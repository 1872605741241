export const ADD_TO_CART = 'ADD TO CART';
export const BUY_NOW = 'BUY NOW';
export const SELECT_MEMBERSHIP_PLAN = 'Please Select Membership Plan';
export const MEMBERSHIP_ADDED = 'Membership Added Successfully';
export const MEMBERSHIP_REMOVED = 'Membership Removed Successfully';
export const SELECT_PLAN_TEXT = 'Select a plan';
export const HB_PRIME_COLOR = '#735fc3';
export const MEMBERSHIP_LOGIN_CTA = 'Please login to Buy Membership';
export const CHANGE_PLAN_CTA = 'CHANGE PLAN';
export const KNOW_MORE_CTA = 'KNOW MORE';
export const BUY_MEMBERSHIP = 'BUY MEMBERSHIP';
export const LOGIN_ERROR_CODE = '401';
export const MEMBERSHIP_PAYMENT_ERROR_MESSAGE = 'Error placing order. You will be shortly redirected to Membership Page.'
export const TNC_DESCRIPTION = 'For more information, please check';
export const TNC = 'Terms & Conditions'
export const HALALBOX_TEXT = 'DZ'
export const Select_TEXT = 'Select'

export const STRIKE_TEXT_LIST = ['retailPrice'];
