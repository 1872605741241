import {all, call, put, select} from 'redux-saga/effects';
import {
  SET_LOADING,
  SET_WALLET,
  SET_RECHARGE_METHODS,
  SET_TRANSACTION_HISTORY,
  UPDATE_WALLET_TRANSACTION,
} from './wallet.action';
import {
  getWalletApi,
  addWalletRechargeApi,
  getRechargeTransactionNoApi,
  postSaveRechargePaymentApi,
  postVerifyRechargeApi,
  getVerifyPaymentModeApi,
  postVerifyPaymentApiPayU,
} from './wallet.api';
import {
  getPaymentMethodsApi,
  postDeliverySlotsApi,
  postSaveUserPaymentApi,
} from '../checkout/checkout.api';
import {
  launchInfoSelector,
  SET_NOTIFICATION_MSG,
  SET_NOTIFICATION_MSG_ANIMATE,
} from '../../initial.action';
import history from '../../history';
import config from 'appConfig';
import {
  CHECKOUT_FAILURE_REDIRECT,
  CHECKOUT_STEPS,
  PAYMENT_GATEWAYS,
  SOURCE,
} from '../../util/constantServices';
import {getPGCustomerIdSaga, placeOrderSaga} from '../checkout/checkout.saga';
import RazorpayService from '../../util/RazorpayService';
import PayuService from '../../util/PayuService';
import {SET_CHECKOUT_LOADING} from '../checkout/checkout.action';
import { RESET_CART_SKUS, SET_CART_QTY_COUNT } from '../cart/cart.action';
import {SET_RECHARGE_FOR_ORDER_MODAL} from '../modal/modal.action';

export function* getWalletSaga({data}) {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: walletData} = yield call(getWalletApi, data);
    const walletHistory = walletData?.walletTransactions;
    if (!config.isApp) {
      data.cb && data.cb(walletData);
    }
    if (data?.pageNo === 0) {
      yield put({type: SET_TRANSACTION_HISTORY});
    }
    if ((walletHistory && data?.pageNo > 0) || data?.pageNo === 0) {
      yield put({
        type: UPDATE_WALLET_TRANSACTION,
        data: walletData?.walletTransactions || [],
      });
    }
    yield put({type: SET_WALLET, data: walletData});
  } catch (e) {
    console.log('Err@ getWalletSaga: ', e);
    yield put({type: SET_LOADING, data: false});
  }
}

export function* addWalletRechargeSaga({data, cb}) {
  try {
    yield put({type: SET_LOADING, data: true});
    yield call(addWalletRechargeApi, data);
    yield put({type: SET_LOADING, data: false});
    if (!config.isApp) {
      yield put({
        type: SET_RECHARGE_FOR_ORDER_MODAL,
        data: {visible: false},
      });
      history.push(data.redirectUrl);
    } else {
      cb();
    }
  } catch (e) {
    console.log('Err@ addWalletRechargeSaga: ', e);
    yield put({type: SET_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG_ANIMATE,
      data:
        e?.data?.description ||
        'Fail to process the recharge. Please try again',
    });
  }
}

export function* getRechargeMethods() {
  try {
    yield put({type: SET_LOADING, data: true});
    const {data: rechargeMethods} = yield call(getPaymentMethodsApi, {
      halalboxMoney: true,
    });
    let pgCid = rechargeMethods.pgCid || null;
    const {pgId} = rechargeMethods;
    let {savedUPIs} = rechargeMethods;
    if (pgId === PAYMENT_GATEWAYS.PAYU.id) {
      savedUPIs = savedUPIs.map(upi => ({...upi, tokenId: Math.random()}));
      rechargeMethods.savedUPIs = savedUPIs;
    }
    if (!pgCid && pgId === PAYMENT_GATEWAYS.RAZORPAY.id) {
      pgCid = yield call(getPGCustomerIdSaga);
      console.log('GoT PGCID:', pgCid);
    }
    yield put({
      type: SET_RECHARGE_METHODS,
      data: {rechargeMethods, pgCid},
    });
    yield put({type: SET_LOADING, data: false});
  } catch (e) {
    console.log('Err@ getRechargeMethods: ', e);
    yield put({type: SET_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG_ANIMATE,
      data: e?.data?.description || 'Fail to get payment modes for recharge.',
    });
  }
}

const processRazorPayPayment = data => {
  const {postDetails, cardData, paymentResp, launchInfo, paymentData} = data;
  const rzrp = RazorpayService.getRazorPayInstance();
  if (!rzrp) {
    return cardData.failCb({
      step: CHECKOUT_STEPS.PG_NOT_LOADED,
      redirect: CHECKOUT_FAILURE_REDIRECT.PG_NOT_LOADED,
    });
  }
  const rzrData = {
    customer_id: cardData.pgCid,
    order_id: paymentResp.pgOrderId,
    ...cardData,
    contact: launchInfo.mobile,
  };
  rzrData.amount = postDetails.amount * 100;
  delete rzrData.mode;
  delete rzrData.payVia;
  delete rzrData.pgCid;
  delete rzrData.pgId;
  delete rzrData.surl;
  delete rzrData.last4;
  delete rzrData.bin;
  delete rzrData.cardType;
  delete rzrData.isRechargeForOrder;
  delete rzrData.orderNo;
  delete rzrData.source;
  const razorPaySuccessCb = resp => {
    console.log('PaymentSuccess: ', resp);
    const verifyData = {
      status: 'success',
      rechargeTxnNo: paymentData.txnNo,
      orderNo: paymentData.orderNo,
      membershipRefNo: paymentData.membershipRefNo,
      success: {
        pgOId: resp.razorpay_order_id,
        pgPId: resp.razorpay_payment_id,
        pgSId: resp.razorpay_signature,
      },
    };
    postVerifyRechargeApi({
      data: verifyData,
    })
      .then(verifiedResp => {
        console.log('Payment verified', verifiedResp);
        if (!config.isApp) {
          cardData.successCb &&
            cardData.successCb({
              tnxNo: paymentData.txnNo,
              orderNo: paymentData.orderNo,
            });
        }
      })
      .catch(e => {
        console.log('Verification error: ', e);
        cardData.failCb &&
          cardData.failCb({
            step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
            error: {redirect: CHECKOUT_FAILURE_REDIRECT.ORDER_FAILURE},
          });
      });
  };
  const razorPayErrorCb = err => {
    console.log('RazorPay error: ', err);
    postVerifyRechargeApi({
      tnxNo: paymentData.txnNo,
      orderNo: paymentData.orderNo,
      data: {
        status: 'failure',
        rechargeTxnNo: paymentData.txnNo,
        orderNo: paymentData.orderNo,
        membershipRefNo: paymentData.membershipRefNo,
        ...err,
      },
    })
      .then(failedResp => {
        console.log('Failed payment reported. ', failedResp);
        if (!config.isApp) {
          cardData.failCb({
            errorMessage:
              err?.error?.description || failedResp.data?.description,
          });
        }
      })
      .catch(e => {
        console.log('Failure reporting error: ', e);
      });
  };
  const {deviceDetector = null} = config;
  console.log('deviceDetector: ', deviceDetector?.deviceDetect());
  console.log(
    'isIos device: ',
    deviceDetector?.isMacOs,
    deviceDetector?.isMobileSafari,
    deviceDetector?.isSafari,
    deviceDetector?.isIOS,
  );
  if (
    deviceDetector?.isMacOs ||
    deviceDetector?.isMobileSafari ||
    deviceDetector?.isSafari ||
    deviceDetector?.isIOS
  ) {
    console.log('Qualifying as apple device.');
    cardData.failCb({
      step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
      redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
      pgDetails: {
        razorPayData: rzrData,
        razorPaySuccessCb,
        razorPayErrorCb,
      },
    });
  } else {
    const payNow = document.createElement('BUTTON');
    payNow.onclick = function() {
      console.log('Qualifying as non-apple device.');
      const razorPayPopup = RazorpayService.makePayment({
        razorPayData: rzrData,
        razorPaySuccessCb,
        razorPayErrorCb,
      });
      setTimeout(() => {
        const hasPopup = !!razorPayPopup?._payment?.popup?.window?.outerHeight;
        // debugger
        if (!hasPopup) {
          console.log('non-apple device popup failed');
          cardData.failCb({
            step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
            redirect: CHECKOUT_FAILURE_REDIRECT.SHOW_PG_INFO_POPUP,
            pgDetails: {
              razorPayData: rzrData,
              razorPaySuccessCb,
              razorPayErrorCb,
            },
          });
        }
      }, 250);
    };
    payNow.click();
  }
};

const processPayuPayment = data => {
  const {postDetails, cardData, paymentResp, launchInfo, resp} = data;
  const payuData = {
    ...postDetails,
    ...cardData,
    firstname: paymentResp.payUHash.hashFirstName,
    email: paymentResp.payUHash.hashEmail,
    phone: launchInfo.mobile,
    txnid: resp.rechargeTxnNo,
    hash: paymentResp.payUHash.paymentHash,
    productinfo: paymentResp.payUHash.productInfo,
    surl: paymentResp.payUHash.successURL,
    curl: paymentResp.payUHash.cancelURL,
    furl: paymentResp.payUHash.failureURL,
    amount: paymentResp.payUHash.amount,
    udf1: paymentResp.payUHash.udf1,
    udf2: paymentResp.payUHash.udf2,
    udf3: paymentResp.payUHash.udf3,
    udf4: paymentResp.payUHash.udf4,
    udf5: paymentResp.payUHash.udf5,
    key: process.env.REACT_APP_PAYU_KEY || 'LlaSG8',
    payuUrl: process.env.REACT_APP_PAYU_POST_URL,
  };
  payuData.user_credentials = `${payuData.key}:${cardData.pgCid}`;
  PayuService.makePayment(payuData);
};

export function* postRechargeWalletSaga(data) {
  const {
    data: cardData,
    razorPayInstance = null,
    payUInstance = null,
    cb,
    appFailedCb = null,
    payUSuccessCb = null,
    payUFailureCb = null,
  } = data;
  // console.log("postRechargeWalletSaga prop check",data)
  try {
    // const nonPOLMode = ['pod', 'cod', 'hbw'];
    if (
      !config.isApp &&
      cardData.pgId === PAYMENT_GATEWAYS.RAZORPAY.id &&
      !RazorpayService.getRazorPayInstance()
    ) {
      RazorpayService.initRazorpay();
    }
    const postDetails = {
      amount: cardData.amount,
      paymentMode: cardData.payVia,
      pgId: cardData.pgId,
      paymentMethod: 'POL',
    };
    if (cardData?.payVia === 'CC') {
      postDetails.cardType = cardData?.cardType; // VISA/MC/RUPAY
      postDetails.last4 = cardData?.cardType;
      postDetails.saveVpa = !!cardData?.save;
    }
    if (cardData?.payVia === 'upi') {
      postDetails.vpa = cardData?.vpa;
      postDetails.saveVpa = !!cardData?.save;
    }
    if (cardData?.payVia === 'netbanking') {
      postDetails.bankCode = cardData?.bank;
    }
    if (cardData?.payVia === 'BNPL') {
      postDetails.bankCode = cardData?.bankcode;
    }
    if (cardData?.wallet) {
      postDetails.paymentWallet = cardData?.wallet;
    }
    // if(nonPOLMode.indexOf(cardData?.mode) > -1) {
    //   postDetails.paymentMode = cardData?.mode;
    //   postDetails.paymentMethod = cardData?.mode;
    // }
    const launchInfo = yield select(launchInfoSelector);
    const {data: resp} = yield call(getRechargeTransactionNoApi);
    console.log('getRechargeTransactionNoApi resp', resp);
    if (resp.step) {
      if (cardData.failCb && !config.isApp) {
        return cardData.failCb(resp);
      } else if (appFailedCb) {
        return appFailedCb(resp);
      }
    }
    let orderNo = '', membershipRefNo='';
    if (cardData.source === SOURCE.RECHARGE_FOR_ORDER) {
      const {data: orderResp} = yield call(placeOrderSaga);
      if (orderResp.step) {
        if (cardData.failCb && !config.isApp) {
          return cardData.failCb(orderResp);
        } else if (appFailedCb) {
          return appFailedCb(orderResp);
        }
      }
      console.log('orderResp recharge==>',orderResp);
      orderNo = orderResp.orderNo;
      membershipRefNo = orderResp.membershipRefNo;
    }

    const paymentData = {
      paymentMode: cardData.mode,
      data: {
        rechargeTxnNo: resp.rechargeTxnNo,
        paymentMode: cardData.mode,
        orderNo,
        membershipRefNo,
        ...postDetails,
      }
    };
    const {data: paymentResp} = yield call(
      postSaveRechargePaymentApi,
      paymentData,
    );
    console.log('save user payment: ', JSON.stringify(paymentResp));
    if (!config.isApp) {
      if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
        return processPayuPayment({
          postDetails,
          cardData,
          paymentResp,
          launchInfo,
          resp,
        });
      } else if (cardData.pgId === PAYMENT_GATEWAYS.RAZORPAY.id) {
        processRazorPayPayment({
          postDetails,
          cardData,
          paymentResp,
          launchInfo,
          paymentData,
        });
      }
    } else {
      if (cardData?.paymentMethod === 'POL') {
        delete cardData?.paymentMethod;
      }
      console.warn(
        'isPayu',
        cardData.pgId === PAYMENT_GATEWAYS.PAYU.id,
        payUInstance,
      );
      if (cardData.pgId === PAYMENT_GATEWAYS.PAYU.id) {
        const payuData = {
          ...postDetails,
          ...cardData,
          firstName: paymentResp.payUHash.hashFirstName,
          email: paymentResp.payUHash.hashEmail,
          phone: launchInfo.mobile,
          transactionId: resp.rechargeTxnNo,
          hash: paymentResp.payUHash.paymentHash,
          productInfo: paymentResp.payUHash.productInfo,
          android_surl: paymentResp.payUHash.successURL,
          android_furl: paymentResp.payUHash.failureURL,
          ios_surl: paymentResp.payUHash.successURL,
          ios_furl: paymentResp.payUHash.failureURL,
          amount: paymentResp.payUHash.amount,
          udf1: paymentResp.payUHash.udf1,
          udf2: paymentResp.payUHash.udf2,
          udf3: paymentResp.payUHash.udf3,
          udf4: paymentResp.payUHash.udf4,
          udf5: paymentResp.payUHash.udf5,
          key: config.ENV.PG_PU_KEY,
          payment: paymentResp.payUHash.paymentHash,
          vas_for_mobile_sdk: paymentResp.payUHash.vasForMobileSDK,
          payment_related_details_for_mobile_sdk:
            paymentResp.payUHash.paymentRelatedDetailsForMobileSDK,
          payUEnvironment: parseInt(config.ENV.PG_PU_ENVIRONMENT, 10),
          payUIosEnvironment: config.ENV.PG_PU_IOS_ENVIRONMENT,
        };
        payuData.userCredentials = `${payuData.key}:${cardData.pgCid}`;
        console.warn('paymentResp', paymentResp);
        console.warn('paymentObject', payuData);
        var paymentObject = {
          payUPaymentParams: payuData,
          payUCheckoutProConfig: {},
        };

        let finalPayUPayload = paymentObject;
        if (config?.getDeviceType() === 'iphone') {
          finalPayUPayload = payuData;
        }

        payUInstance.openCheckoutScreen(
          finalPayUPayload,
          (payUresponse, merchantResponse) => {
            // error callback
            console.warn('PayU payUresponse Error', payUresponse);
            console.log('PayU merchantResponse Error--', merchantResponse);
            const verifyData = {
              orderNo: orderNo,
              membershipRefNo: membershipRefNo,
              status: 'failure',
              payUResponse:JSON.parse(payUresponse),
              ...merchantResponse,
            };
            postVerifyPaymentApiPayU({
              rechargeTxnNo: resp.rechargeTxnNo,
              data: verifyData,
            })
              .then(validateResponse => {
                // payU error with validation error success
                console.warn('payU error with validation API success');
                // payUFailureCb(payUresponse, merchantResponse);
                payUSuccessCb(payUresponse, merchantResponse, validateResponse);
              })
              .catch(() => {
                // payU error with validation failed
                console.warn('payU error with validation failed');
                payUFailureCb(payUresponse, merchantResponse);
              });
          },
          (payUresponse, merchantResponse) => {
            console.log('Inside payu sucess ------ ');
            //success callback
            const verifyData = {
              status: 'success',
              orderNo: orderNo || '',
              membershipRefNo: membershipRefNo || '',
              sdkCallBackStatus:'success',
              rechargeTxnNo: resp.rechargeTxnNo,
              payUResponse:JSON.parse(payUresponse),
              // ...merchantResponse,
            };
            console.warn('PayU success payUresponse', payUresponse);
            console.warn('PayU success merchantResponse', merchantResponse);

            postVerifyPaymentApiPayU({
              rechargeTxnNo: resp.rechargeTxnNo,
              data: verifyData,
            })
              .then(validateResponse => {
                // payU success with validation success
                console.warn(
                  'payU success with validation success',
                  validateResponse,
                );
                let isOrder = false;
                const isOrderDone = paymentData?.data?.orderNo || paymentData?.data?.membershipRefNo
                if (isOrderDone) isOrder = true;
                payUSuccessCb(
                  payUresponse,
                  merchantResponse,
                  validateResponse,
                  isOrder,
                );
              })
              .catch(err => {
                // payU success with validation failed
                console.warn('payU success with validation failed', err);
                payUFailureCb(payUresponse, merchantResponse);
              });
          },
        );
        return;
      }
      const rzrData = {
        customer_id: cardData.pgCid,
        order_id: paymentResp.pgOrderId,
        ...cardData,
        contact: launchInfo.mobile,
        currency: 'INR',
      };
      rzrData.amount = postDetails.amount * 100;
      delete rzrData.mode;
      delete rzrData.payVia;
      delete rzrData.pgCid;
      delete rzrData.pgId;
      delete rzrData.surl;
      delete rzrData.orderNo;
      delete rzrData.source;

      console.warn('Payment by App', rzrData, config.ENV.PG_KEY);
      razorPayInstance
        .open(rzrData)
        .then(resp => {
          // handle success
          const verifyData = {
            status: 'success',
            tnxNo: paymentData?.txnNo,
            orderNo: paymentData?.orderNo || '',
            membershipRefNo: paymentData?.membershipRefNo || '',
            success: {
              pgOId: resp?.razorpay_order_id,
              pgPId: resp?.razorpay_payment_id,
              pgSId: resp?.razorpay_signature,
            },
          };
          console.warn(
            'Razorpay verifyData',
            verifyData,
            paymentData,
            paymentData.txnNo,
          );

          const reqbody = {
            data: verifyData,
          };
          postVerifyRechargeApi(reqbody)
            .then(verifiedResp => {
              console.log('Payment verified', verifiedResp);
              if (config.isApp) {
                // here fail
                cb(verifiedResp);
              }
            })
            .catch(e => {
              console.log('Verification error: ', e);
              appFailedCb({
                step: CHECKOUT_STEPS.PAYMENT_VERIFICATION,
                redirect: CHECKOUT_FAILURE_REDIRECT.ORDER_FAILURE,
              });
            });
        })
        .catch(err => {
          // handle failure
          console.warn('RazorPay error: ', err);
          let errorMsg = '';
          let statusCode = '';
          try {
            errorMsg = JSON.parse(err?.description || {})?.error;
            statusCode = JSON.parse(err?.description || {})?.http_status_code;
          } catch (e) {
            console.warn('Error Parsing Razorpay error', e);
            errorMsg = {
              error: err?.description,
            };
            statusCode = '';
          }
          const verifyData = {
            status: 'failure',
            error: errorMsg,
            http_status_code: statusCode,
          };
          const reqbody = {
            tnxNo: paymentData?.txnNo,
            orderNo: paymentData?.orderNo || '',
            membershipRefNo: paymentData?.membershipRefNo || '',
            data: verifyData,
          };
          postVerifyRechargeApi(reqbody)
            .then(failedResp => {
              // const formattedError = (failedResp?.data?.description ) || (err?.description && JSON.parse(err?.description) && JSON.parse(err?.description)?.error?.description);
              console.warn(
                'Failed payment reported. ',
                failedResp,
                JSON.parse(err?.description).description,
              );
              // appFailedCb(formattedError);

              appFailedCb(failedResp);
            })
            .catch(e => {
              console.warn('Failure reporting error: ', e);
              cb(err?.description);
            });
        });
    }
  } catch (e) {
    console.log('Err @postRechargeWalletSaga: ', e);
    let message = 'Error while placing order. Please try again.';
    if (e.data?.description) {
      message = e.data?.description;
    }
    yield put({type: SET_NOTIFICATION_MSG, data: {show: true, message}});
    cb && cb(e);
    cardData.failCb && cardData.failCb(e);
  }
}

function* placeHBWOrderSaga(paymentMode) {
  console.log('Inside placeHBWOrderSaga ');
  try {
    const {data: resp} = yield call(placeOrderSaga);
    const postDetails = {
      amount: 0,
      paymentMode,
      paymentMethod: paymentMode,
      orderNo: resp?.orderNo || '',
      membershipRefNo: resp?.membershipRefNo || '',
    };
    if (resp.step) return resp;
    const paymentData = {
      paymentMode,
      data: postDetails,
    };
    const {data: orderResp} = yield call(postSaveUserPaymentApi, paymentData);
    return orderResp;
  } catch (e) {
    console.log('Err @placeHBWOrder: ', e);
    const message =
      e.data?.description || 'Error while placing order using wallet.';
    yield put({type: SET_NOTIFICATION_MSG, data: {message}});
  }
}

export function* postPayAndPlaceOrderSaga({data, cb = null, failCb = null}) {
  try {
    yield put({type: SET_CHECKOUT_LOADING, data: true});
    if (!data.skipShipmentSlot) yield call(postDeliverySlotsApi, data);
    const {
      data: {isHBW, isHBP},
    } = yield call(getVerifyPaymentModeApi);
    // IF Fully Payable using wallet.
    if (isHBW || isHBP) {
      const paymentMode = isHBW ? 'hbw' : 'hbp'
      const orderResp = yield call(placeHBWOrderSaga, paymentMode);
      const orderNo = orderResp?.orderNo || orderResp?.membershipRefNo
      console.log('Wallet postPayAndPlaceOrderSaga ==>',orderResp);
      // IF Fail to create Order:
      if (orderResp.step) {
        if (!config.isApp) {
          if (data.failCb) data.failCb(orderResp);
          return;
        } else {
          failCb(orderResp);
        }
      }
      const transactionNo = orderResp?.orderNo ? orderResp?.orderNo : orderResp?.membershipRefNo
      yield put({ type: RESET_CART_SKUS });
      if (!config.isApp) {
        yield put({type: SET_CART_QTY_COUNT, data: 0});
        yield put({type: SET_CHECKOUT_LOADING, data: false});
        history.push(`/co/order/${transactionNo}`);
      } else {
        yield put({type: SET_CHECKOUT_LOADING, data: false});
        let resp = {
          orderNo: orderNo,
          isResetToHome: true,
        };
        cb(resp);
      }
    } else {
      if (!config.isApp) {
        let redirectUrl = `/co/dz-wallet`;
        if (data.isDesktop)
          redirectUrl = `${data.redirectUrl}?source=${data.source}`;
        history.push(redirectUrl);
      } else {
        // APP Handling for redirection to wallet screen.
        let resp = {
          orderNo: '',
          isResetToHome: false,
        };
        cb(resp);
      }
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
  } catch (e) {
    console.log('Err @postPayAndPlaceOrderSaga: ', e);
    let errorMsg = 'Error saving shipment and placing order';
    if (e.status === 401) {
      errorMsg = 'Unauthorised Access! Please login to access this service';
    }
    if (e.data?.description) {
      errorMsg = e.data?.description;
    }
    yield put({type: SET_CHECKOUT_LOADING, data: false});
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: errorMsg,
      },
    });
  }
}
