import { select, call, all, put } from 'redux-saga/effects';
import {
  SET_KYC_DETAILS,
  SET_KYC_DOCS,
  SET_KYC_STATUS,
  SET_LOADING,
} from './kyc.action';
import {
  getKycDetailsApi,
  getKycDocsApi,
  getKycDocumentTypesApi,
  putKycDetailsApi,
  putKycDocApi, submitForKycApprovalApi,
  uploadKycDocApi
} from './kyc.api';
import { SET_NOTIFICATION_MSG } from '../../initial.action';
import { selectKycDetails } from './kyc.selector';
import { sortAlphabetically } from '../../util/miscellaneousServices';
import { KYC_STATUS } from '../../util/constantServices';

export function* getKycDetailsSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const { data: kycDetails } = yield call(getKycDetailsApi);
    const newKycStatus = {
      status: kycDetails.kycStatus,
      entityType: kycDetails.entityTypeName,
    };
    yield put({ type: SET_KYC_STATUS, data: newKycStatus });
    yield put({ type: SET_KYC_DETAILS, data: { kycDetails } });
  } catch (e) {
    console.log('Err@ getKycDetailsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: { show: true, message: 'Fail to get kyc details.' },
    });
  }
}
export function* validateEntityData(putData) {
  let errorMessage = '';
  if (
    putData.entityTypeId === 1 &&
    !putData.contactPersonName &&
    putData.entityName
  ) {
    putData.contactPersonName = putData.entityName;
  }
  if (errorMessage) throw { errorMessage };
  return putData;
}
export function* putKycDetailsSaga({ data }) {
  try {
    yield put({ type: SET_LOADING, data: true });
    const validatedData = yield call(validateEntityData, data);
    const { data: kycDetails } = yield call(putKycDetailsApi, validatedData);
    if (kycDetails) {
      const newKycStatus = {
        status: kycDetails.kycStatus,
        entityType: kycDetails.entityTypeName,
      };
      yield put({ type: SET_KYC_STATUS, data: newKycStatus });
      yield put({ type: SET_KYC_DETAILS, data: { kycDetails } });
    }
  } catch (e) {
    console.log('Err@ putKycDetailsSaga: ', e);
    const message = e?.error?.errorMessage || 'Fail to update kyc details.';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
    yield put({ type: SET_LOADING, data: false });
  }
}

export function* getKycDocsSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    const [{ data: uploadedDocs }, { data: docTypes }] = yield all([
      call(getKycDocsApi),
      call(getKycDocumentTypesApi),
    ]);
    const kycDocs = docTypes.map(d => {
      const userDoc = uploadedDocs?.find(
        ud => ud.documentTypeId === d.documentTypeId
      );
      return userDoc ? { ...d, ...userDoc } : d;
    });
    // console.log('kycDocs: ', kycDocs);
    // console.log('sortedKycDocs: ', sortAlphabetically(kycDocs, 'documentName'));

    yield put({ type: SET_KYC_DOCS, data: { kycDocs: sortAlphabetically(kycDocs, 'documentName'), docTypes } });
  } catch (e) {
    console.log('Err@ getKycDocsSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: { show: true, message: 'Fail to get kyc docs.' },
    });
  }
}

export function* uploadAndUpdateKycDocSaga({ data }) {
  const { doc, successCb = () => {}, failCb = () => {} } = data;
  try {
    const { data: uploadResp } = yield call(uploadKycDocApi, doc?.fileData);
    const { url: fileUrl, uuid: documentRefId } = uploadResp[doc.newFileName];
    const docData = {
      ...doc,
      documentRefId,
      fileUrl,
    };
    delete docData.fileData;
    const { data: updateResp } = yield call(putKycDocApi, docData);
    const { kycDocs } = yield select(selectKycDetails);
    const newKycDocs = kycDocs.map(d => {
      if (d.documentTypeId === docData.documentTypeId) {
        return { ...d, ...docData, ...updateResp };
      } else return d;
    });
    yield put({ type: SET_KYC_DOCS, data: { kycDocs: newKycDocs } });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Document uploaded!',
      }
    });
    successCb({ ...docData, updateResp });
  } catch (e) {
    console.log('Err@ uploadAndUpdateKycDocSaga: ', e);
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: {
        show: true,
        message: 'Fail to upload and update kyc docs.'
      }
    });
    const errorMessage = e?.data?.error?.errorMessage || 'Fail to upload and update kyc docs.';
    failCb({ ...doc, errorMessage });
  }
}
export function* submitForKycApprovalSaga() {
  try {
    yield put({ type: SET_LOADING, data: true });
    yield call(submitForKycApprovalApi);
    yield put({ type: SET_KYC_STATUS, data: { status: KYC_STATUS.WAITING_FOR_APPROVAL } });
    yield put({ type: SET_LOADING, data: false });
    yield put({
      type: SET_NOTIFICATION_MSG,
      data: { show: true, message: 'Your documents are sent for kyc approval.' }
    });
  } catch (e) {
    console.log('Err@ submitForKycApprovalSaga: ', e);
    yield put({ type: SET_LOADING, data: false });
    const message =
      e?.error?.errorMessage || 'Fail to submit docs for kyc approval.';
    yield put({ type: SET_NOTIFICATION_MSG, data: { show: true, message } });
  }
}
