import { takeEvery } from 'redux-saga/effects';
import {
  GET_KYC_DETAILS,
  GET_KYC_DOCS,
  PUT_KYC_DETAILS,
  PUT_KYC_DOC,
  PUT_KYC_STATUS,
} from './kyc.action';
import {
  getKycDetailsSaga,
  getKycDocsSaga,
  putKycDetailsSaga,
  submitForKycApprovalSaga,
  uploadAndUpdateKycDocSaga
} from './kyc.saga';

export const KycSaga = [
  takeEvery(GET_KYC_DETAILS, getKycDetailsSaga),
  takeEvery(PUT_KYC_DETAILS, putKycDetailsSaga),
  takeEvery(GET_KYC_DOCS, getKycDocsSaga),
  takeEvery(PUT_KYC_DOC, uploadAndUpdateKycDocSaga),
  takeEvery(PUT_KYC_STATUS, submitForKycApprovalSaga),
];
