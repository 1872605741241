import { getItem } from 'hb-redux-store/util/localStorageService';
import { CP_MAPPING_KEY } from 'hb-redux-store/util/constantServices';

let routes = getItem(CP_MAPPING_KEY) || null;

export const setMapping = r => (routes = r);

export const getCPMapping = () => routes;

export const createProductUrl = productId => {
  const product = routes?.products?.find(p => p.productId == productId);
  if (product) return `/${product.categorySeoName}/${product.productSeoName}`;
  return null;
};

export const createComboProductUrl = comboProductId => {
  const product = routes?.comboProducts?.find(
    p => p.comboProductId == comboProductId,
  );
  if (product) return `/combo-items/${comboProductId}`;
  // return null;
  return `/combo-items/${comboProductId}`;
};

export const createCategoryUrl = categoryId => {
  const category = routes?.categories?.find(c => c.categoryId == categoryId);
  if (category) return `/${category.categorySeoName}`;
  return null;
};

export const ACTION_TYPE_DETAILS = {
  PDP: {
    type: 'PDP',
    getUri: actionValue => createProductUrl(actionValue) || '#',
  },
  PLP: {
    type: 'PLP',
    getUri: actionValue => createCategoryUrl(actionValue) || '#',
  },
  SPL_PAGE: {
    type: 'SPL_PAGE',
    getUri: actionValue => `${actionValue}-spl`,
  },
  LINK: {
    type: 'LINK',
    getUri: actionValue => actionValue,
  },
  NOTIFICATION: {
    type: 'NOTIFICATION',
    getUri: () => '/notifications',
  },
  OFFERS: {
    type: 'OFFERS',
    getUri: () => '/offers',
  },
  ORDER_DETAILS: {
    type: 'ORDER_DETAILS',
    getUri: orderNo => `/my/order/${orderNo}`,
  },
  PROFILE: {
    type: 'PROFILE',
    getUri: () => '/my/details',
  },
  REFER_N_EARN: {
    type: 'REFER_N_EARN',
    getUri: () => '/my/referrals',
  },
  WALLET: {
    type: 'WALLET',
    getUri: () => '/my/dz-wallet',
  },
  CART: {
    type: 'CART',
    getUri: () => '/cart',
  },
  BLOG: {
    type: 'BLOG',
    getUri: () => '/blogs',
  },
  BLOG_DETAIL: {
    type: 'BLOG_DETAIL',
    getUri: blogUrl => `/${blogUrl}-bg`,
  },
  RAMADAN: {
    type: 'RAMADAN',
    getUri: () => '/ramadan-subscription',
  },
  LOGIN: {
    type: 'LOGIN',
    getUri: () => '/login',
  },
  FAQ: {
    type: 'FAQ',
    getUri: () => '/help/faqs',
  },
  TNC: {
    type: 'TNC',
    getUri: () => '/help/tnc',
  },
  PRIVACY: {
    type: 'PRIVACY',
    getUri: () => '/help/privacy-policy',
  },
  HB_SELECT: {
    type: 'HB_SELECT',
    getUri: () => '/dz-select',
  },
  HB_POINTS: {
    type: 'HB_POINTS',
    getUri: () => '/my/hb-point',
  },
  HB_MONEY: {
    type: 'HB_MONEY',
    getUri: () => '/my/dz-wallet',
  },
  NO_ACTION: {
    type: 'NO_ACTION',
    path: '#',
    getUri: () => '#',
  },
};

export const getActionLink = ctColumn => {
  const { actionUri = null, actionType = null, actionValue = null } = ctColumn;
  if (!(actionUri || actionType)) return '#';
  if (actionUri) return actionUri;
  const action = ACTION_TYPE_DETAILS[actionType];
  return action?.getUri(actionValue) || '#';
};
